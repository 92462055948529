.event-screens {
  background-image: url("//psg/background_mobile.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  max-width: 480px;
  text-align: center;
  padding-top: 2.5%;

  @media (min-width: 768px) {
    background-image: url("//psg/background_desktop.png");
  }
}
