@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    --color-primary: #fff;
    --color-secondary: #000;
    --color-button-play: #e30613;
    --color-button-admin: #7950f2;
    --color-warning: #fa5252;
  }
  @import "themes/psg.css";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "QuizCustomFont";
  src: url("./../public/BarlowCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Team-A-Bold";
  src: url("../public/PSG-Bold_Installable.otf") format("truetype");
}

@font-face {
  font-family: "Team-A-Light";
  src: url("../public/PSG-Light_Installable.otf") format("truetype");
}

@font-face {
  font-family: "Team-A-Regular";
  src: url("../public/PSG-Regular_Installable.otf") format("truetype");
}

/* Overwrite Mantine CSS */
.mantine-Modal-modal {
  overflow: hidden;
}
