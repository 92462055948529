.nav-wrapper {
  background-color: #fff;
  color: #ceab5d;
  font-size: 24px;
  margin: 15px 0px;
  max-width: 480px;
  width: 100%;
}

.nav-title {
  color: #ceab5d;
  display: flex;
  font-size: 24px;
  justify-content: center;
  padding-right: 30px;
}

.nav-item {
  color: #ceab5d;
  display: flex;
  font-size: 24px;
  justify-content: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
