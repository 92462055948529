.page1, .page2 {
    width: 100%;
}

@font-face {
  font-family: "PSGCustomFont-Bold";
  src: url("../../../../public/PSG-Bold_Installable.otf") format("truetype");
}

@font-face {
  font-family: "PSGCustomFont-Light";
  src: url("../../../../public/PSG-Light_Installable.otf") format("truetype"),
}

@font-face {
  font-family: "PSGCustomFont-Regular";
  src: url("../../../../public/PSG-Regular_Installable.otf") format("truetype"),
}

@media screen and (min-width: 280px) {
  .psg-text {
    font-size: 0.45rem;
  }
}

@media screen and (min-width: 320px) {
  .psg-text {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 375px) {
  .psg-text {
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 414px) {
  .psg-text {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 720px) {
  .psg-text {
    font-size: 1rem;
  }
}
