/* styles.css */
.pdf-container {
  max-width: 100%;
  /* max-height: 80vh; */
  overflow: auto;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pdf-container canvas {
  max-width: 100%;
  height: auto;
}

.pdf-controls {
  text-align: center;
  margin-top: 10px;
  color: rgb(255 255 255);
}

button {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}
